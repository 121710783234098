import { Badge, Button, Divider, Space, Table } from "antd";
import dayjs from "dayjs";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { downloadFile } from "../../../shared/downloadFile";
import {
  compareDates,
  compareStrings,
} from "../../../shared/table/sorterFunctions";

import {
  getDateFilterProps,
  getTextFilterProps,
} from "../../../shared/table/filterFunctions";
import { setIsDownloading } from "../../../store/downloads/downloadsSlice";
import { useNavigate } from "react-router-dom";

const quoteStatusToBadgeStatus = (quoteStatus) => {
  var status = quoteStatus?.toLowerCase() ?? "";
  if (status == "pending") {
    return "default";
  } else if (status == "complete") {
    return "success";
  } else if (status == "sent") {
    return "warning";
  } else if (status == "in progress") {
    return "processing";
  }
  return "error";
};

const ApplicationsSearchTable = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const data = useSelector((state) => state.applicationsSearch.data);
  const isLoading = useSelector((state) => state.applicationsSearch.isLoading);
  const isDownloading = useSelector((state) => state.downloads.isDownloading);

  const latestDownloadedRecord = useRef(null);

  useEffect(() => {
    dispatch(setIsDownloading(false));
  }, []);

  const columns = [
    {
      title: "Insured Name",
      dataIndex: "main_owner_name",
      key: "main_owner_name",
      width: 150,
      sorter: (a, b) => compareStrings(a, b, "main_owner_name"),
      ...getTextFilterProps("main_owner_name", "Insured Name"),
    },
    {
      title: "Aircraft",
      key: "aircraft",
      dataIndex: "aircraft",
      width: 175,
      sorter: (a, b) => compareStrings(a, b, "aircraft"),
      ...getTextFilterProps("aircraft", "Aircraft"),
    },
    {
      title: "Submission Date",
      key: "submission_date",
      dataIndex: "submission_date",
      width: 175,
      render: (data) => dayjs(data).format("YYYY-MM-DD hh:mm A"),
      sorter: (a, b) => compareDates(a, b, "submission_date"),
      ...getDateFilterProps("submission_date"),
    },
    {
      title: "Quote",
      key: "quote_status",
      dataIndex: "quote_status",
      width: 100,
      render: (data) => (
        <Badge
          status={quoteStatusToBadgeStatus(data)}
          text={data ?? "Unknown"}
        />
      ),
      sorter: (a, b) => compareStrings(a, b, "quote_status"),
      filters: [
        {
          text: "Complete",
          value: "Complete",
        },
        {
          text: "In Progress",
          value: "In Progress",
        },
        {
          text: "Pending",
          value: "Pending",
        },
        {
          text: "Sent",
          value: "Sent",
        },
      ],
      onFilter: (value, record) => record.quote_status == value,
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      width: 175,
      render: (value, record) => {
        return `(${value.slice(0, 3)}) ${value.slice(3, 6)}-${value.slice(
          6
        )} (${record.phone_type ?? "NAN"})`;
      },
      sorter: (a, b) => compareStrings(a, b, "phone"),
      ...getTextFilterProps("phone", "Phone"),
    },
    {
      title: "Email",
      key: "email",
      dataIndex: "email",
      sorter: (a, b) => compareStrings(a, b, "email"),
      ...getTextFilterProps("email", "Email"),
      width: 250,
    },
    {
      key: "action",
      width: 130,
      fixed: "right",
      align: "center",
      render: (_, record) => (
        <Space>
          <Button
            size="small"
            type="link"
            loading={
              isDownloading && latestDownloadedRecord.current == record.id
            }
            disabled={
              isDownloading && latestDownloadedRecord.current != record.id
            }
            onClick={() => {
              latestDownloadedRecord.current = record.id;
              dispatch(
                downloadFile({
                  defaultName: "PrivateAviationForm",
                  url: `/private_aviation/forms/${record.id}/serve_pdf`,
                })
              );
            }}
          >
            Print
          </Button>
          <Divider type="vertical" style={{ marginLeft: 0, marginRight: 0 }} />
          <Button
            size="small"
            type="link"
            onClick={() => {
              navigate(`/applications/quote/${record.id}`);
            }}
          >
            Quote
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={data}
      size="small"
      scroll={{ x: 1160, y: 500 }}
      // virtual
      bordered
      sticky
      pagination={{ defaultPageSize: 50, pageSizeOptions: [10, 20, 50, 100] }}
      loading={isLoading}
    />
  );
};

export default ApplicationsSearchTable;
