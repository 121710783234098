import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { yupResolver } from "@hookform/resolvers/yup";
import { Alert, Button, Card, Col, Flex, Form, Row, Tabs } from "antd";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { CustomInput } from "../../shared/formFields/CustomInput";
import { CustomInputPassword } from "../../shared/formFields/CustomInputPassword";
import { LoginStatus } from "../../store/user/userSlice";
import { loginUser } from "../../store/user/userThunks";
import { useAuthStatus } from "./useAuthStatus";

const loginFormSchema = yup.object().shape({
  email: yup.string().required("Email required").email("Email invalid"),
  password: yup.string().required("Password required"),
});

const Login = () => {
  const { isLoggedIn } = useAuthStatus();

  return isLoggedIn ? (
    <Navigate to="/" replace={true} />
  ) : (
    <Flex justify="center" align="center" className="expanded-height" vertical>
      <Card style={{ minWidth: 350 }}>
        <Tabs
          defaultActiveKey="1"
          centered
          items={[
            {
              label: `Air1 Insurance - Login`,
              key: "1",
              children: <LoginForm />,
            },
          ]}
        />
      </Card>
    </Flex>
  );
};

const LoginForm = () => {
  const {
    handleSubmit,
    control,
    formState: { isValid, isSubmitted },
  } = useForm({
    mode: "onSubmit",
    defaultValues: {
      email: "",
      password: "",
    },
    resolver: yupResolver(loginFormSchema),
  });

  //
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //
  const message = useSelector((state) => state.user.message);
  const status = useSelector((state) => state.user.status);
  const [isAlertVisible, setIsAlertVisible] = useState(true);

  useEffect(() => {
    if (status === LoginStatus.SUCCESS) {
      navigate("/");
    } else if (status === LoginStatus.ERROR) {
      setIsAlertVisible(true);
    }
  }, [status]);

  const onSubmit = (values) => {
    dispatch(loginUser(values));
  };

  return (
    <Form
      name="login_form"
      autoComplete="off"
      onFinish={() => handleSubmit(onSubmit)()}
      style={{ marginTop: 16, marginBottom: 14 }}
    >
      {isAlertVisible && message && (
        <Form.Item>
          <Alert
            closeIcon={true}
            message={message}
            type="error"
            showIcon
            onClose={() => setIsAlertVisible(false)}
          />
        </Form.Item>
      )}

      <CustomInput
        formItem={{ required: true }}
        control={control}
        name="email"
        placeholder="Email"
        prefix={<UserOutlined />}
      />

      <CustomInputPassword
        formItem={{ required: true }}
        control={control}
        name="password"
        prefix={<LockOutlined />}
        type="password"
        placeholder="Password"
      />

      <Form.Item>
        <Row justify="end">
          <Col>
            <Button type="link" disabled={true}>
              Forgot password
            </Button>
          </Col>
        </Row>
      </Form.Item>

      <Row>
        <Col span={24}>
          <Button
            type="primary"
            htmlType="submit"
            loading={status === LoginStatus.PENDING}
            disabled={isSubmitted && !isValid}
            style={{ width: "100%" }}
          >
            Login
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default Login;
