import { ReloadOutlined, SearchOutlined } from "@ant-design/icons";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Card, Col, Form, Row, Space } from "antd";
import dayjs from "dayjs";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { APPLICATIONS_SEARCH_OPTIONS } from "../../../shared/constants";
import { CustomDateRangePicker } from "../../../shared/formFields/CustomDateRangePicker";
import { CustomInput } from "../../../shared/formFields/CustomInput";
import { CustomSelectField } from "../../../shared/formFields/CustomSelectField";
import { clearApplicationsSearch } from "../../../store/applicationsSearch/applicationsSearchSlice";
import { searchApplications } from "../../../store/applicationsSearch/applicationsSearchThunks";
import ApplicationsSearchTable from "./ApplicationsSearchTable";
import { applicationsSearchSchema } from "./applicationsSearchSchema";

const initialValues = {
  date_range: [dayjs().startOf("month"), dayjs()],
  search_criteria: null,
  search_type: null,
};

const ApplicationsSearch = () => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid },
  } = useForm({
    mode: "onSubmit",
    defaultValues: initialValues,
    values: initialValues,
    shouldFocusError: false,
    resetOptions: {
      keepDirty: false,
      keepDirtyValues: false,
      keepErrors: true,
    },
    resolver: yupResolver(applicationsSearchSchema),
  });

  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.applicationsSearch.isLoading);

  const onFinish = (values) => {
    dispatch(searchApplications(values));
  };

  useEffect(() => {
    dispatch(searchApplications(initialValues));
    return () => {
      dispatch(clearApplicationsSearch());
    };
  }, []);
  return (
    <div>
      <Card className="body-card-content-no-min-height">
        <Form
          name="applications_search"
          autoComplete="off"
          size="small"
          labelWrap
          onFinish={() => {
            handleSubmit(onFinish)();
          }}
        >
          <Row gutter={12} justify={"space-between"}>
            <Col>
              <CustomDateRangePicker
                formItem={{
                  required: true,
                  label: "Date range",
                }}
                control={control}
                name={"date_range"}
              />
            </Col>
            <Col>
              <CustomSelectField
                options={APPLICATIONS_SEARCH_OPTIONS}
                formItem={{
                  required: false,
                  label: "Search By",
                }}
                style={{ width: "175px" }}
                control={control}
                name="search_type"
                placeholder="Search By"
              />
            </Col>
            <Col>
              <CustomInput
                formItem={{
                  label: "Search Criteria",
                  required: false,
                }}
                control={control}
                style={{ width: "200px" }}
                name="search_criteria"
                placeholder="Search Criteria"
              />
            </Col>
          </Row>
          <div style={{ textAlign: "right" }}>
            <Space size="small">
              <Button
                style={{ width: "90px" }}
                type="primary"
                size="small"
                htmlType="submit"
                disabled={!isValid || isLoading}
              >
                <SearchOutlined /> Search
              </Button>
              <Button
                style={{ width: "90px" }}
                size="small"
                onClick={() => {
                  reset(initialValues);
                  dispatch(searchApplications(initialValues));
                }}
              >
                <ReloadOutlined /> Reset
              </Button>
            </Space>
          </div>
        </Form>
      </Card>
      <Card className="body-card-content-no-min-height">
        <ApplicationsSearchTable />
      </Card>
    </div>
  );
};

export default ApplicationsSearch;
