import { createSlice } from "@reduxjs/toolkit";
import { loginUser, logoutUser } from "./userThunks";

export const LoginStatus = {
  UNKNOWN: "unknown",
  SUCCESS: "success",
  ERROR: "error",
  PENDING: "pending",
};

const initialState = {
  isAuthenticated: false,
  status: LoginStatus.UNKNOWN,
  message: null,
  name: "",
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    resetUser: {
      reducer(state) {
        state.status = LoginStatus.UNKNOWN;
        state.message = null;
        state.name = "";
        state.isAuthenticated = false;
      },
    },
    setIsAuthenticated: {
      reducer(state, action) {
        state.isAuthenticated = action.payload;
      },
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.status = LoginStatus.PENDING;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.status = LoginStatus.SUCCESS;
        state.name = action.payload.name;
        state.message = null;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.status = LoginStatus.ERROR;
        state.message = action.payload;
      })
      .addCase(logoutUser.fulfilled, (state) => {
        state.status = LoginStatus.UNKNOWN;
        state.message = null;
        state.name = "";
        state.isAuthenticated = false;
      })
      .addCase(logoutUser.rejected, (state) => {
        state.status = LoginStatus.UNKNOWN;
        state.message = null;
        state.name = "";
        state.isAuthenticated = false;
      })
      .addCase(logoutUser.pending, (state) => {
        state.status = LoginStatus.PENDING;
        state.isAuthenticated = false;
      });
  },
});

export const { resetUser, setIsAuthenticated } = userSlice.actions;

export default userSlice.reducer;
