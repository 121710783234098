export const emptyPilotObject = {
  pilot_first_name: "",
  pilot_last_name: "",
  pilot_occupation: "",
  pilot_dob: null,
  license_ratings: [],
  has_had_accidents: null,
  accidents_claim_amount: "",
  recurrent_training: "",
  total_all_aircraft: null,
  total_make_model: null,
  total_floats: null,
  total_amphibian: null,
  total_retract: null,
  total_tailwheel: null,
  total_multi: null,
  total_turbine: null,
  total_rotor: null,
  total_last_ninety_days: null,
  total_last_twelve_months_all: null,
  total_last_twelve_months_make_model: null,
  notes_and_comments: "",
  membership_numbers: [{ number: "", type: null }],
};
