import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import dayjs from "dayjs";
import {
  notifyApiCallFailed,
  showNotification,
} from "../notifications/notificationsSlice";

export const logoutUser = createAsyncThunk("user/logout", async (_, api) => {
  const baseUrl =
    process.env.REACT_APP_USE_LOCAL_API_URL === "true"
      ? process.env.REACT_APP_LOCAL_API_URL
      : process.env.REACT_APP_EXTERNAL_API_URL;
  let config = {
    headers: {
      Authorization: localStorage.getItem("token"),
    },
  };
  localStorage.removeItem("token");
  localStorage.removeItem("expiry");
  try {
    await axios.delete(baseUrl + "/users/sign_out", config);
  } catch (_) {}
  return api.fulfillWithValue(true);
});

export const loginUser = createAsyncThunk("user/login", async (user, api) => {
  const baseUrl =
    process.env.REACT_APP_USE_LOCAL_API_URL === "true"
      ? process.env.REACT_APP_LOCAL_API_URL
      : process.env.REACT_APP_EXTERNAL_API_URL;
  try {
    const response = await axios.post(baseUrl + "/users/sign_in", {
      user: user,
    });

    const token = response?.headers?.getAuthorization();
    if (token) {
      const expiry = dayjs().add(12, "hour").unix().toString();
      localStorage.setItem("token", token);
      localStorage.setItem("expiry", expiry);

      return response.data;
    }
    api.dispatch(notifyApiCallFailed());
    return api.rejectWithValue(null);
  } catch (error) {
    if (error?.response?.status === 401) {
      const message =
        error.response.data?.errors?.[0]?.message ?? "Something went wrong!";
      api.dispatch(
        showNotification({
          type: "error",
          message: "Error",
          description: (
            <div
              dangerouslySetInnerHTML={{
                __html: message.replace(/(?:\r\n|\r|\n)/g, "<br />"),
              }}
            />
          ),
        })
      );
      return api.rejectWithValue(message);
    } else {
      api.dispatch(notifyApiCallFailed());
      return api.rejectWithValue(null);
    }
  }
});
