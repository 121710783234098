import {
  FileTextOutlined,
  FormOutlined,
  LogoutOutlined,
} from "@ant-design/icons";

/*
    Object structure:
        - label: Used in side menu & breadcrumbs. Also is used for page name if 'name' is not provided
        - name: Used as page name
        - icon: Used in side menu & breadcrumbs
    
    You must break down all subroutes into one element. 
    For example: /forms/private-aviation become 2 separate entries:
        - "/forms"
        - "/private-aviation"
*/
export const privatePagesMap = {
  "/logout": { label: "Logout", icon: <LogoutOutlined /> },
  "/forms": { label: "Forms", icon: <FormOutlined /> },
  "/private-aviation": {
    label: "Private Aviation",
    name: "Private Aviation Application",
  },
  "/applications": { label: "Applications", icon: <FileTextOutlined /> },
  "/search": { label: "Search" },
  "/quote": { label: "Quote" },
};
